/* eslint-disable camelcase */
const aws = require('aws-sdk')

const secretKey = process.env.VUE_APP_AWS_SECRET_KEY
const accessID = process.env.VUE_APP_AWS_ACCESS_ID
const region = process.env.VUE_APP_AWS_REGION
export const bucket = process.env.VUE_APP_AWS_BUCKET

aws.config.update({
  secretAccessKey: secretKey,
  accessKeyId: accessID,
})

export const s3 = new aws.S3({
  signatureVersion: 'v4',
  region,
})

export const getUploadedFile = (folder, fileName, imgRef, outerThis) => {
  if (folder) {
    const key = `${folder}/${fileName}`
    s3.put
    s3.getObject(
      {
        Bucket: bucket,
        Key: key,
      },
      (err, data) => {
        if (err) {
        }
        // console.log(imgRef.src)
        // const blob = new Blob([data.Body])
        // const url = URL.createObjectURL(blob)
        // imgRef.src = url
        // outerThis.accountInfo.profile_image = key
      },
    )
  }
}

export const singleUpload = (
  file,
  folder,
  fileName,
  load,
  error,
  progress,
  abort,
  imgRef,
  outerThis,
  company_edit = false,
) => {
  let key = `${folder}+${fileName}`
  let downloadUrl = null

  if ('isLoading' in outerThis) {
    outerThis.isLoading = true
  }
  outerThis.$http
    .get(`/api/pre-signed-url-filename/${key}/${file.type.replace('/', '+')}`)
    .then(response => {
      const record = response.data
      if (record.url) {
        const presignUrl = record.url

        fetch(presignUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          body: file,
        })
          .then(resp => {
            if (resp.ok) {
              outerThis.$http
                .get(`/api/download-pre-signed-url/${key}/${file.type.replace('/', '+')}`)
                .then(downloadResp => {
                  const responseData = downloadResp.data
                  downloadUrl = responseData.download_url

                  load(downloadUrl)
                  if (load) {
                    load(downloadUrl)
                  }

                  key = key.replace('+', '/')
                  if (folder === 'company-profile-images') {
                    if (!company_edit) {
                      if (outerThis.$route.name === 'edit-corporate') {
                        outerThis.companyInfo.company_image = key
                        outerThis.companyInfo.company_image_url = downloadUrl
                        outerThis.saveProfile(true)
                      } else if (outerThis.$route.name === 'companies-edit') {
                        outerThis.companyData.company_image = key
                        outerThis.companyInfo.company_image_url = downloadUrl
                        outerThis.put_company(false)
                      }
                    }
                  } else if (folder === 'ownership-documents') {
                    if (!company_edit) {
                      outerThis.companyInfo.ownership_document_file = key
                      outerThis.ownership_document_file = key
                      outerThis.companyInfo.ownership_document_file_url = downloadUrl
                      outerThis.ownership_document_file_url = downloadUrl
                      outerThis.saveProfile(true)
                    }
                  } else if (folder === 'loa-documents') {
                    if (['edit-event', 'applicant-tracking-system-edit', 'applicant-tracking-system-edit-specific'].includes(outerThis.$route.name)) {
                      const update_company_info = {
                        id: outerThis.$store.state.auth.ActiveUser.company_id,
                        loa_file: key,
                      }
                      outerThis.$http
                        .post('/api/company-info', { company_info: update_company_info })
                        .then(res => {
                          const { success } = res.data
                          if (success) {
                            outerThis.$toastSuccess('Corporate Profile Update','LOA. updated successfully')
                            outerThis.loa_file = key
                            outerThis.loa_file_url = downloadUrl
                          }
                        })
                        .catch(err => {
                          outerThis.$toastDanger('Corporate Profile Update', 'Your company profile has failed. Try again later!')
                        })
                    } else {
                      if (!company_edit) {
                        outerThis.companyInfo.loa_file = key
                        outerThis.loa_file = key
                        outerThis.companyInfo.loa_file_url = downloadUrl
                        outerThis.loa_file_url = downloadUrl
                        outerThis.saveProfile(true)
                      }
                    }
                  } else if (folder === 'event-images') {
                    outerThis.formData.banner_image = key
                    outerThis.formData.banner_image_url = downloadUrl
                  } else if (folder === 'user-resume') {
                    if (outerThis.user) {
                      outerThis.user.resume_file = key //! this is causing the re-download of the file
                      outerThis.resume_file = key
                      outerThis.resume_updated = true
                      outerThis.user.resume_updated = true
                      outerThis.user.resume_file_url = downloadUrl
                      outerThis.resume_file_url = downloadUrl
                    }

                    if (outerThis.accountInfo) {
                      outerThis.accountInfo.resume_file = key
                      outerThis.accountInfo.resume_file_url = downloadUrl
                      outerThis.accountInfo.resume_updated = true
                    }

                    if (
                      !['listings-page', 'view-jobs'].includes(
                        outerThis.$route.name,
                      )
                    ) {
                      outerThis.viewUploadedResume()
                    }
                    try {
                      outerThis.saveProfile()
                      
                    } catch (err) {}
                  } else if (folder === 'ats-bulk-upload') {
                    outerThis.$http
                      .get(outerThis.bulk_upload_url)
                      .then(response => {
                        outerThis.$refs.refJobForm.modalBulkUploadFailed.errorDetails = []
                        if (outerThis.$isNotEmpty(response.data.errors)) {
                          outerThis.$set(outerThis.$refs.refJobForm.modalBulkUploadFailed, 'errorDetails', response.data.errors)
                          outerThis.$refs.refJobForm.modalBulkUploadFailed.state = true
                        } else {
                          outerThis.$toastSuccess('File Upload', response.data.message)

                          outerThis.$router.push('/applicant-tracking')

                          // outerThis.$refs.uploadCSV.value = "";
                          outerThis.file_upload = ''
                        }
                        outerThis.showLoading = false
                        outerThis.isLoading = false
                      })
                      .catch(error => {
                        console.error(error)
                        outerThis.showLoading = false
                        outerThis.isLoading = false
                      })
                  }
                  if (!['event-images', 'user-resume', 'ats-bulk-upload'].includes(folder)) {
                    if (company_edit) {
                      outerThis.userData.profile_image = key
                      outerThis.userData.profile_image_url = downloadUrl
                      outerThis.saveUserProfile()
                    } else {
                      outerThis.accountInfo.profile_image = key
                      outerThis.accountInfo.profile_image_url = downloadUrl
                      outerThis.saveProfile()
                    }
                  }

                  if ('isLoading' in outerThis) {
                    outerThis.isLoading = false
                  }
                }).catch(e => {
                  // console.log(e)
                  if ('isLoading' in outerThis) {
                    outerThis.isLoading = false
                  }
                })
            } else {
              console.error('File upload failed.')

              if ('isLoading' in outerThis) {
                outerThis.isLoading = false
              }
            }
          })
          .catch(err => {
            console.error('Error:', err)
            if ('isLoading' in outerThis) {
              outerThis.isLoading = false
            }
          })
      }
    })
    .catch(error => {
      if ('isLoading' in outerThis) {
        outerThis.isLoading = false
      }
    })
  // s3.upload(
  //   {
  //     Bucket: bucket,
  //     Key: key,
  //     Body: file,
  //     ContentType: file.type,
  //   },
  //   (err, data) => {
  //     if (err) {
  //       error('Something is not right. Try again later')
  //     }
  //     load(data.Key)

  //     // getUploadedFile(folder, fileName, imgRef, outerThis)
  //   },
  // )
}

export const deleteObjectByKey = (key, outerThis, company_edit = false) => {
  const params = {
    Bucket: bucket,
    Key: key,
  }
  s3.deleteObject(params, (err, data) => {
    if (data && company_edit) {
      outerThis.userData.profile_image = 'null'
      outerThis.saveUserProfile()
    }
  })
}

export const renameObjectByKey = (key, newKey) => {
  s3.copyObject({
    Bucket: bucket,
    CopySource: `event-images/${key}`,
    Key: newKey,
  })
    .promise()
    .then(() => s3
      .deleteObject({
        Bucket: bucket,
        Key: key,
      })
      .promise())
    .catch(e => console.error(e))
}
